import http from "@/utils/http"
/**
 * 创建兑换订单
 * @param {object} params
 */
export function create(params) {
    return http({
        url: "/pointexchange/api/ordercreate/create",
        data: params
    })
}
export function calculate(params) {
    return http({
        url: "/pointexchange/api/ordercreate/calculate",
        data: params
    })
}

/**
 * 创建兑换订单
 * @param {object} params
 */
export function payment(params) {
    return http({
        url: "/pointexchange/api/ordercreate/payment",
        data: params
    })
}

