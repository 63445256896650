<template>
    <div>
        <!--收货地址-->
        <div class="item-block" v-if="orderPaymentData.is_virtual == 0">
            <div class="block-text">收货地址</div>

            <div clsas="address-list">
                <!--<div class="address-item" @click="addAddressShow">
					<div class="add-address">
						<i class="el-icon-circle-plus-outline"></i>
						添加收货地址
					</div>
				</div>-->

                <div class="address-item" v-for="(item, key) in memberAddress" :key="item.id"
                     :class="addressId == item.id ? 'active' : ''" v-if="key < 3 || (addressShow && key >= 3)">
                    <div class="address-info">
                        <div class="options">
                            <!--<div @click="editAddress(item.id)">编辑</div>-->
                            <template v-if="item.is_default == 0">
                                <el-popconfirm title="确定要删除该地址吗？" @onConfirm="deleteMemberAddress(item.id)">
                                    <div slot="reference">删除</div>
                                </el-popconfirm>
                            </template>
                        </div>
                        <div class="address-name">{{ item.name }}</div>
                        <div class="address-mobile" @click="setMemberAddress(item.id)">{{ item.mobile }}</div>
                        <div class="address-desc" @click="setMemberAddress(item.id)">
                            {{ item.full_address }}
                            {{ item.address }}
                        </div>
                    </div>
                </div>

                <div v-if="memberAddress.length > 3 && !addressShow" @click="addressShow = true"
                     class="el-button--text address-open">
                    <i class="el-icon-arrow-down"></i>
                    更多收货地址
                </div>

                <div class="clear"></div>
            </div>
        </div>
        <!--购买虚拟类商品需填写您的手机号-->
        <div class="item-block" v-if="orderPaymentData.is_virtual == 1">
            <div class="block-text">购买虚拟类商品需填写您的手机号，以方便商家与您联系</div>

            <el-form ref="form" size="mini" class="mobile-wrap" label-width="80px">
                <el-form-item label="手机号码">
                    <el-input placeholder="请输入您的手机号码" maxlength="11"
                              v-model="orderCreateData.member_address.mobile"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <!--收货地址添加-->
        <el-dialog v-if="memberAddress.length" :title="addressForm.id == 0 ? '添加收货地址' : '编辑收货地址'" :visible.sync="dialogVisible"
                   width="32%">
            <el-form ref="form" :rules="addressRules" :model="addressForm" label-width="80px">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="addressForm.name" placeholder="收货人姓名"></el-input>
                </el-form-item>

                <el-form-item label="手机" prop="mobile">
                    <el-input v-model="addressForm.mobile" maxlength="11" placeholder="收货人手机号"></el-input>
                </el-form-item>

                <el-form-item label="电话">
                    <el-input v-model="addressForm.telephone" placeholder="收货人固定电话（选填）"></el-input>
                </el-form-item>

                <el-form-item class="area" label="地区" prop="area">
                    <el-row :gutter="10">
                        <el-col :span="7">
                            <el-select prop="province" ref="province" v-model="addressForm.province_id"
                                       @change="getAddress(1)" placeholder="请选择省">
                                <el-option label="请选择省" value="0"></el-option>
                                <el-option v-for="item in pickerValueArray" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="7">
                            <el-select ref="city" prop="city" v-model="addressForm.city_id" @change="getAddress(2)"
                                       placeholder="请选择市">
                                <el-option label="请选择市" value="0"></el-option>
                                <el-option v-for="item in cityArr" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="7">
                            <el-select ref="district" prop="district" v-model="addressForm.district_id"
                                       placeholder="请选择区/县">
                                <el-option label="请选择区/县" value="0"></el-option>
                                <el-option v-for="item in districtArr" :key="item.id" :label="item.name"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-form-item>

                <el-form-item label="详细地址" prop="address">
                    <el-input v-model="addressForm.address" placeholder="定位小区、街道、写字楼"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addmemberAddress('form')">确 定</el-button>
            </span>
        </el-dialog>




        <!--商品信息-->
        <div class="item-block">
            <div class="goods-list">
                <table>
                    <tr style="display: flex; flex-wrap: wrap; width: 100%">
                        <td style="flex: 0 0 50%" width="12.5%">商品</td>
                        <td style="flex: 1" width="12.5%">价格</td>
                        <td style="flex: 1" width="12.5%">数量</td>
                        <td style="flex: 1" width="12.5%">小计</td>
                    </tr>
                </table>
            </div>
        </div>
        <div>
            <div class="item-block">
                <div class="goods-list">
                    <table v-if="orderPaymentData.exchange_info">
                        <tr style="display: flex; flex-wrap: wrap; width: 100%">
                            <td style="flex: 0 0 50%" width="50%">
                                <div class="goods-info">
                                    <div class="goods-info-left">
                                        <img class="goods-img" :src="$img(orderPaymentData.exchange_info.image, { size: 'mid' })"
                                             @error="imageError(goodsIndex)" />
                                    </div>
                                    <div class="goods-info-right">
                                        <div>{{ orderPaymentData.exchange_info.name }}</div>
                                        <!-- 规格 -->
                                        <div class="goods-spec">
                                            <span>厂家：{{ orderPaymentData.goods_info.goods_attr_format.attr_factory }}</span><br/>
                                            <span>规格：{{ orderPaymentData.goods_info.goods_attr_format.attr_specs }}</span><br/>
                                            <span>效期：{{ orderPaymentData.goods_info.goods_attr_format.attr_validity }}</span>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td style="flex: 1" width="12.5%" class="goods-price">
                                <div v-if="orderPaymentData.exchange_info.pay_type == 1" class="color-base-text">
                                    <span class="goods-price">{{ orderPaymentData.exchange_info.point }}</span>
                                    <span class="unit">积分</span>
                                    <template v-if="orderPaymentData.exchange_info.price != '0.00'">
                                        <span class="unit"> + </span>
                                        <span class="goods-price"> ￥{{ orderPaymentData.exchange_info.price }}</span>
                                    </template>
                                </div>
                            </td>
                            <td style="flex: 1" width="12.5%" class="goods-num">
                                {{ orderPaymentData.num }}
                            </td>
                            <td style="flex: 1" width="12.5%" class="goods-money">
                                <div v-if="orderPaymentData.exchange_info.pay_type == 1" class="color-base-text">
                                    <span class="goods-price">{{ orderPaymentData.exchange_info.point }}</span>
                                    <span class="unit">积分</span>
                                    <template v-if="orderPaymentData.exchange_info.price != '0.00'">
                                        <span class="unit"> + </span>
                                        <span class="goods-price"> ￥{{ orderPaymentData.exchange_info.price }}</span>
                                    </template>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <!-- 买家留言 -->
        <div class="item-block padd-bom-10">
            <div class="block-text">买家留言:</div>
            <el-input rows="3" type="textarea" placeholder="留言前建议先与商家协调一致"
                      v-model="orderCreateData.buyer_message" class="buyer-message" @input="orderCreateData.buyer_message" maxlength="140"
                      show-word-limit resize="none"></el-input>
        </div>

        <!-- 总计 -->
        <div class="item-block">
            <div class="order-statistics">
                <table>
                    <tr>
                        <!--                        减去运费-->
                        <td align="right">所需积分：</td>
                        <td align="left" class="colorsize">
                            {{ orderPaymentData.point }}积分
                        </td>
                    </tr>

                </table>
            </div>
            <div class="clear"></div>
        </div>

        <!--结算-->
        <div class="item-block" v-if="orderPaymentData.exchange_info">
            <div class="order-submit">
                <div class="order-money">应付：
                    <div class="ns-text-color">
                        <span class="goods-price">{{ orderPaymentData.exchange_info.point }}</span>
                        <span class="unit">积分</span>
                        <template v-if="orderPaymentData.exchange_info.price != '0.00'">
                            <span class="unit"> + </span>
                            <span class="goods-price"> ￥{{ orderPaymentData.exchange_info.price }}</span>
                        </template>
                    </div>
                </div>
                <el-button type="primary" class="el-button--primary" @click="orderCreate">订单结算</el-button>

            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
   import payment from './payment';
   export default {
       mixins: [payment]
   }
</script>


<style lang="scss" scoped>
    @import "./payment.scss";

    .free-goods-content {
        box-sizing: border-box;
        width: 100%;
        padding: 10px;
        //border: 1px solid red;
        overflow-y: auto;
        display: flex;
        gap: 20px;
        margin-bottom: 20px;

        .goods-content {
            padding: 10px;
            width: 180px;
            flex-shrink: 0;
        }

        .goods-content-checked {
            border: 2px solid $base-color;
            border-radius: 3px;
        }

        .freeGoodsAttrItem {
            line-height: normal;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 12px;
        }
    }

    /* For Webkit browsers */
    .free-goods-content::-webkit-scrollbar {
        width: 10px;
    }

    .free-goods-content::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 5px;
    }

    .free-goods-content::-webkit-scrollbar-thumb {
        background-color: $base-color;
        border-radius: 6px;
        min-width: 30px; /* 设置滚动条的最小宽度 */
        //&:hover {
        //    background: $base-color;
        //}
    }

    .free-goods-content::-webkit-scrollbar-track {
        width: 20px;
        border-radius: 10px;
        background: #888;
        //background-color: #1890ff;
    }
</style>
