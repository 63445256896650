import { payment, calculate, create } from "@/api/promotion/point"
import { addressList, deleteAddress, setDefault } from "@/api/member/member"

export default {
    data() {
        var checkMobile = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号"))
            } else if (!/^1[3|4|5|6|7|8|9][0-9]{9}$/.test(value)) {
                callback(new Error("手机号格式错误"))
            } else {
                callback()
            }
        }
        return {
            memberAddress: [],
            addressShow: false,
            addressId: 0, //收货地址

            dialogVisible: false,
            addressForm: {
                id: 0,
                name: "",
                mobile: "",
                telephone: "",
                province_id: "",
                city_id: "",
                district_id: "",
                community_id: "",
                address: "",
                full_address: "",
                is_default: "",
                longitude: "",
                latitude: ""
            },
            addressRules: {
                name: [
                    {
                        required: true,
                        message: "请输入收货人",
                        trigger: "blur"
                    },
                    {
                        min: 1,
                        max: 20,
                        message: "长度在 1 到 20 个字符",
                        trigger: "blur"
                    }
                ],
                mobile: [
                    {
                        required: true,
                        validator: checkMobile,
                        trigger: "change"
                    }
                ],
                province: [
                    {
                        required: true,
                        message: "请选择省",
                        trigger: "change"
                    }
                ],
                city: [
                    {
                        required: true,
                        message: "请选择市",
                        trigger: "change"
                    }
                ],
                district: [
                    {
                        required: true,
                        message: "请选择区/县",
                        trigger: "change"
                    }
                ],
                address: [
                    {
                        required: true,
                        message: "请输入详细地址",
                        trigger: "change"
                    }
                ]
            },
            pickerValueArray: {},
            cityArr: {},
            districtArr: {},

            orderPaymentData: {
                member_address: {
                    mobile: ""
                }
            },


            orderCreateData: {
                num: 1,
            }
        }
    },
    created() {
        Object.assign(this.orderCreateData, this.$route.query)
        this.getMemberAddress()
        this.getOrderPaymentData()
    },
    methods: {
        // 收获地址
        getMemberAddress() {
            addressList({
                page_size: 0
            })
                .then((res) => {
                    const { code, message, data } = res
                    if (data && data.list) {
                        let that = this
                        this.memberAddress = data.list
                        data.list.forEach(function(e) {
                            if (e.is_default == 1) {
                                that.addressId = e.id
                            }
                        })
                    }
                })
                .catch((err) => {
                    const { code, message, data } = err
                    this.$message.error(message)
                })
        },
        //删除客户收货地址
        deleteMemberAddress(params) {
            deleteAddress({
                id: params
            })
                .then((res) => {
                    const { code, message, data } = res
                    if (data) {
                        this.$message({
                            message: message,
                            type: "success"
                        })
                        this.getMemberAddress()
                    } else {
                        this.$message({
                            message: message,
                            type: "warning"
                        })
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        },
        //设置客户收货地址
        setMemberAddress(params) {
            this.addressId = params
            setDefault({
                id: params
            })
                .then((res) => {
                    const { code, message, data } = res
                    this.orderCalculate()
                })
                .catch((err) => {
                    const { code, message, data } = err
                    this.$message.error(message)
                })
        },


        //获取地址
        getAddress(type) {
            let pid = 0
            let that = this
            switch (type) {
                case 0:
                    //加载省
                    pid = 0
                    break
                case 1:
                    //加载市
                    pid = this.addressForm.province_id
                    that.cityArr = {}
                    that.districtArr = {}
                    this.addressForm.city_id = ""
                    this.addressForm.district_id = ""
                    break
                case 2:
                    //加载区县
                    pid = this.addressForm.city_id
                    that.districtArr = {}
                    this.addressForm.district_id = ""
                    break
            }

            getArea({
                pid: pid
            })
                .then((res) => {
                    const { code, message, data } = res
                    if (data) {
                        switch (type) {
                            case 0:
                                that.pickerValueArray = data
                                break
                            case 1:
                                //加载市
                                that.cityArr = data
                                break
                            case 2:
                                //加载区县
                                that.districtArr = data
                                break
                        }
                    }
                    if (data.length == 0) {
                        this.addresNextType = false
                    }
                })
                .catch((err) => {
                    const { code, message, data } = err
                    this.$message.error(message)
                })
        },
        //新增/编辑收货地址
        addmemberAddress(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.isSend) {
                        return false
                    }

                    if (!this.addressForm.id) {
                        this.addressForm.full_address = this.$refs.province.selectedLabel + "-" + this.$refs.city.selectedLabel + "-" + this.$refs.district.selectedLabel
                        let data = {
                            name: this.addressForm.name,
                            mobile: this.addressForm.mobile,
                            telephone: this.addressForm.telephone,
                            province_id: this.addressForm.province_id,
                            city_id: this.addressForm.city_id,
                            district_id: this.addressForm.district_id,
                            community_id: "",
                            address: this.addressForm.address,
                            full_address: this.addressForm.full_address,
                            longitude: this.addressForm.longitude,
                            latitude: this.addressForm.latitude,
                            is_default: this.addressForm.is_default,
                            url: "add"
                        }

                        if (!data.province_id || data.province_id <= 0) {
                            this.$message({
                                message: "请正确选择省",
                                type: "warning"
                            })
                            return false
                        }
                        if (!data.city_id || data.city_id <= 0) {
                            this.$message({
                                message: "请正确选择市",
                                type: "warning"
                            })
                            return false
                        }
                        if ((!data.district_id || data.district_id <= 0) && this.addresNextType == true) {
                            this.$message({
                                message: "请正确选择区/县",
                                type: "warning"
                            })
                            return false
                        }
                        this.isSend = true

                        saveAddress(data)
                            .then((res) => {
                                const { code, message, data } = res
                                if (data) {
                                    this.setMemberAddress(data)

                                    this.$message({
                                        message: message,
                                        type: "success"
                                    })
                                    this.dialogVisible = false
                                    this.getMemberAddress()
                                    this.getOrderPaymentData()
                                } else {
                                    this.$message({
                                        message: message,
                                        type: "warning"
                                    })
                                }
                                this.isSend = false
                            })
                            .catch((err) => {
                                const { code, message, data } = err
                                this.$message.error(message)
                            })
                    } else {
                        this.addressForm.full_address = this.$refs.province.selectedLabel + "-" + this.$refs.city.selectedLabel + "-" + this.$refs.district.selectedLabel
                        let data = this.addressForm
                        if (!data.province_id) {
                            this.$message({
                                message: "请选择省",
                                type: "warning"
                            })
                            return false
                        }
                        if (!data.city_id) {
                            this.$message({
                                message: "请选择市",
                                type: "warning"
                            })
                            return false
                        }
                        if (!data.district_id) {
                            this.$message({
                                message: "请选择区/县",
                                type: "warning"
                            })
                            return false
                        }
                        this.isSend = true
                        this.setMemberAddress(data.id)
                        data.url = "edit"
                        saveAddress(data)
                            .then((res) => {
                                const { code, message, data } = res
                                if (data) {
                                    this.$message({
                                        message: message,
                                        type: "success"
                                    })
                                    this.dialogVisible = false
                                    this.getMemberAddress()
                                    this.getOrderPaymentData()
                                } else {
                                    this.$message({
                                        message: message,
                                        type: "warning"
                                    })
                                }
                                this.isSend = false
                            })
                            .catch((err) => {
                                const { code, message, data } = err
                                this.$message.error(message)
                            })
                    }
                } else {
                    return false
                }
            })
        },


        // 订单数据
        getOrderPaymentData() {

            payment(this.orderCreateData)
                .then((res) => {
                    const { code, message, data } = res

                    if (code >= 0) {
                        this.orderPaymentData = data
                        this.goodsItem = data.goods_info
                        this.handlePaymentData()
                    } else {
                        this.$message({
                            message: "未获取到创建订单所需数据！", //提示的信息
                            type: "warning",
                            offset: 225,
                            duration: 3000,
                            onClose: () => {
                                this.$router.go(-1)
                                return false
                            }
                        })
                        return
                    }
                })
                .catch((err) => {
                    const { code, message, data } = err
                    // this.$message.error(message)
                })
        },
        /**
         * 处理结算订单数据
         */
        handlePaymentData() {
            this.orderCreateData.delivery = {}
            this.orderCreateData.buyer_message = ""

            var data = this.orderPaymentData
            if (data.express_type != undefined && data.express_type[0] != undefined) {
                var express_type = data.express_type
                this.orderCreateData.delivery.store_id = 0

                var delivery_type = express_type[0].name
                if (delivery_type == "store") {
                    this.member_address = {
                        mobile: data.member_account.mobile != "" ? data.member_account.mobile : ""
                    }
                }
                var delivery_type_name = express_type[0].title

                this.orderCreateData.delivery.delivery_type = delivery_type
                this.orderCreateData.delivery.delivery_type_name = delivery_type_name

                // 如果默认配送方式是门店配送模拟点击门店tab选项
                // if (express_type[0].name == "store") {
                //     this.storeSelected(express_type[0])
                // }
            }


            if (this.orderPaymentData.is_virtual) this.orderCreateData.member_address = {
                mobile: data.member_account.mobile != "" ? data.member_account.mobile : ""
            }

            if (this.orderPaymentData.is_virtual == 0) {
                // 是否显示时间选择
                if (this.orderPaymentData.local_config.info && this.orderPaymentData.local_config.info.time_is_open == 1) {
                    this.timeInfo.showTimeBar = true
                    // 当日是否支持配送
                    if (this.orderPaymentData.local_config.info.time_week.length == 0 ||
                        this.orderPaymentData.local_config.info.time_week.length == 7 ||
                        this.orderPaymentData.local_config.info.time_week.indexOf(this.timeInfo.week) > -1) {
                        this.canLocalDelicery = true
                    } else {
                        this.canLocalDelicery = false
                    }

                    if (this.orderPaymentData.local_config.info.time_type == 0) {
                        this.deliveryWeek = "全天"
                    } else if (this.orderPaymentData.local_config.info.time_week.length > 0) {

                        if (this.orderPaymentData.local_config.info.time_week.length == 7) {
                            this.deliveryWeek = "全天"
                        } else {
                            this.timeInfo.showTime = true
                            // 判断配送时间是连续还是间隔
                            var timeWeek = this.orderPaymentData.local_config.info.time_week
                            var is_interval = false // 是否间隔
                            for (var i = 0; i < timeWeek.length; i++) {
                                if ((i + 1) < timeWeek.length) {
                                    var difference = timeWeek[i + 1] - timeWeek[i]
                                    if (difference > 1) {
                                        is_interval = true
                                        break
                                    }
                                }
                            }

                            var weeks = ["周日", "周一", "周二", "周三", "周四", "周五", "周六"]
                            if (is_interval) {
                                var temp = []
                                for (var i = 0; i < timeWeek.length; i++) {
                                    temp.push(weeks[timeWeek[i]])
                                }
                                this.deliveryWeek = temp.join("、")
                            } else {
                                this.deliveryWeek = weeks[timeWeek[0]] + "至" + weeks[timeWeek[timeWeek.length - 1]]
                            }
                        }
                    } else {
                        this.deliveryWeek = "店铺未设置配送时间"
                    }

                    // 将timepicker选中当前时间
                    var h = new Date().getHours().toString()
                    var m = new Date().getMinutes().toString()
                    if (h.length == 1) {
                        h = "0" + h
                    }
                    if (m.length == 1) {
                        m = "0" + m
                    }

                    this.orderCreateData.buyer_ask_delivery_time = h + ":" + m

                    // picker组件时间起始
                    let start_time = this.orderPaymentData.local_config.info.start_time
                    this.timeInfo.start_time = this.getTimeStr(start_time)

                    let end_time = this.orderPaymentData.local_config.info.end_time
                    this.timeInfo.end_time = this.getTimeStr(end_time)

                    var current_time = new Date(this.$util.timeStampTurnTime(this.orderPaymentData.timestamp))
                    var hour = current_time.getHours()
                    var minute = current_time.getMinutes()

                    var start_hour = parseInt(this.timeInfo.start_time.split(":")[0])
                    var start_minute = parseInt(this.timeInfo.start_time.split(":")[1])

                    var end_hour = parseInt(this.timeInfo.end_time.split(":")[0])
                    var end_minute = parseInt(this.timeInfo.end_time.split(":")[1])

                    // 检测当天是否能够配送，然后判断送达时间。不在配送时间当日不能下单，例：配送时间是周一到周五，那么周末不能下单，周一到周五可以下单
                    if (this.canLocalDelicery) {

                        // 判断是否全天
                        if (!(start_hour == end_hour && start_minute == end_minute)) {

                            // 当前时间早于配送时间，送达时间：开始时间~结束时间
                            if ((hour < start_hour) || (hour == start_hour && minute < start_minute)) {
                                this.orderCreateData.buyer_ask_delivery_time = (start_hour.toString().length == 1 ? "0" + start_hour :
                                        start_hour) + ":" +
                                    (start_minute.toString().length == 1 ? "0" + start_minute : start_minute)
                            }

                            // if (((hour > start_hour && hour < end_hour) || (hour == start_hour && minute > start_minute) || (hour ==
                            // 		start_hour && minute >= start_minute && hour < end_hour))) {
                            // }

                            // 当前时间晚于配送时间，送达时间隐藏，不能下单
                            if ((hour > end_hour) || (hour == end_hour && minute > end_minute)) {
                                this.canLocalDelicery = false
                            }
                        }

                    }

                } else {
                    this.deliveryWeek = "店铺未开启配送时间"
                }
            }

            Object.assign(this.orderPaymentData, this.orderCreateData)

            this.orderCalculate()
        },


        orderCalculate() {
            this.fullscreenLoading = true
            var data = this.$util.deepClone(this.orderCreateData)
            data.delivery = JSON.stringify(data.delivery)
            if (this.orderCreateData.delivery.delivery_type == "store") {
                data.member_address = JSON.stringify(this.member_address)
            } else {
                data.member_address = JSON.stringify(data.member_address)
            }
            calculate(data)
                .then((res) => {
                    if (res.code >= 0) {
                        this.orderPaymentData.member_address = res.data.member_address
                        this.orderPaymentData.delivery_money = res.data.delivery_money
                        this.orderPaymentData.order_money = res.data.order_money
                        this.$forceUpdate()
                    } else {
                        this.$util.showToast({
                            title: res.message
                        })
                    }
                    this.fullscreenLoading = false
                })
                .catch((err) => {
                    const { code, message, data } = err
                    this.$message.error(message)
                    this.fullscreenLoading = false
                })
        },
        // 订单验证
        verify() {
            if (this.orderPaymentData.exchange_info.type == 1) {
                if (this.orderPaymentData.is_virtual == 1) {
                    if (!this.orderCreateData.member_address.mobile.length) {
                        this.$util.showToast({
                            title: "请输入您的手机号码"
                        })
                        return false
                    }
                    var reg = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
                    if (!reg.test(this.orderCreateData.member_address.mobile)) {
                        this.$util.showToast({
                            title: "请输入正确的手机号码"
                        })
                        return false
                    }
                }

                if (this.orderPaymentData.is_virtual == 0) {
                    if (this.orderCreateData.delivery.delivery_type != "store") {
                        if (!this.orderPaymentData.member_address) {
                            this.$util.showToast({
                                title: "请先选择您的收货地址"
                            })
                            return false
                        }
                    }

                    if (this.orderCreateData.delivery.delivery_type == "store") {
                        if (!this.orderCreateData.delivery.store_id) {
                            this.$util.showToast({
                                title: "没有可提货的门店,请选择其他配送方式"
                            })
                            return false
                        }
                        if (!this.member_address.mobile) {
                            this.$util.showToast({
                                title: "请输入预留手机"
                            })
                            return false
                        }
                        var reg = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
                        if (!reg.test(this.member_address.mobile)) {
                            this.$util.showToast({
                                title: "请输入正确的预留手机"
                            })
                            return false
                        }

                        // if(!this.orderCreateData.buyer_ask_delivery_time){
                        // 	this.$util.showToast({
                        // 		title: '请输入自提时间'
                        // 	});
                        // 	return false;
                        // }
                    }

                    if (this.orderCreateData.delivery.delivery_type == "local") {

                        if (this.canLocalDelicery) {

                            // 判断是否全天
                            if (!(start_hour == end_hour && start_minute == end_minute)) {

                                var hour = parseInt(this.orderCreateData.buyer_ask_delivery_time.split(":")[0])
                                var minute = parseInt(this.orderCreateData.buyer_ask_delivery_time.split(":")[1])

                                var start_hour = parseInt(this.timeInfo.start_time.split(":")[0])
                                var start_minute = parseInt(this.timeInfo.start_time.split(":")[1])

                                var end_hour = parseInt(this.timeInfo.end_time.split(":")[0])
                                var end_minute = parseInt(this.timeInfo.end_time.split(":")[1])

                                // 当前时间早于配送时间
                                if (((hour < start_hour) || (hour == start_hour && minute < start_minute))) {
                                    this.$util.showToast({
                                        title: "送达时间不能小于配送开始时间"
                                    })
                                    return false
                                }

                                // 当前时间在配送时间内，送达时间：开始时间~结束时间
                                if (!((hour > start_hour && hour < end_hour) || (hour == start_hour && minute > start_minute) || (hour ==
                                    start_hour && minute >= start_minute && hour < end_hour))) {
                                    this.$util.showToast({
                                        title: "送达时间范围：开始时间~结束时间"
                                    })
                                    return false
                                }
                            }
                        } else {
                            this.$util.showToast({
                                title: "本店已休息"
                            })
                            return false
                        }

                    }

                    let deliveryVerify = true

                    for (let key in this.orderCreateData.delivery) {
                        if (JSON.stringify(this.orderCreateData.delivery[key]) == "{}") {
                            deliveryVerify = false
                            this.$util.showToast({
                                title: "店铺\"" + this.orderPaymentData[key].site_name + "\"未设置配送方式"
                            })
                            break
                        }
                        if (this.orderCreateData.delivery[key].delivery_type == "store" && this.orderCreateData.delivery[key].store_id ==
                            0) {
                            deliveryVerify = false
                            this.$util.showToast({
                                title: "店铺\"" + this.orderPaymentData[key].site_name + "\"没有可提货的门店,请选择其他配送方式"
                            })
                            break
                        }
                    }
                    if (!deliveryVerify) return false

                }

            }
            return true
        },
        orderCreate() {
            if (this.verify()) {
                if (this.isSub) return
                if (this.orderCreateData.is_invoice == 1) {
                    if (this.invoiceVerify() === false) return
                }

                this.isSub = true

                var loading = this.$loading({
                    lock: true,
                    text: "订单提交中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                })
                var data = this.$util.deepClone(this.orderCreateData)
                data.delivery = JSON.stringify(data.delivery)
                data.coupon = JSON.stringify(data.coupon)
                if (this.orderCreateData.delivery.delivery_type == "store") {
                    data.member_address = JSON.stringify(this.member_address)
                } else {
                    data.member_address = JSON.stringify(data.member_address)
                }
                create(data)
                .then((res) => {
                    const { code, message, data } = res
                    loading.close()
                    if (code >= 0) {
                        this.$store.dispatch("order/removeOrderCreateData", "")
                        if (this.orderPaymentData.pay_money == 0) {
                            this.$router.push({
                                path: "/result",
                                query: {
                                    code: data
                                }
                            })
                        } else {
                            this.$router.push({
                                path: "/pay",
                                query: {
                                    code: data
                                }
                            })
                        }
                    } else {
                        this.isSub = false
                        this.$message({
                            message: message,
                            type: "warning"
                        })
                    }
                })
                .catch((err) => {
                    loading.close()
                    this.isSub = false
                    const { code, message, data } = err
                    this.$message.error(message)
                })
            }
        }

    },

    filters: {
        /**
         * 金额格式化输出
         * @param {Object} money
         */
        moneyFormat(money) {
            return parseFloat(money).toFixed(2)
        },
        /**
         * 店铺优惠摘取
         */
        promotion(data) {
            let promotion = ""
            if (data) {
                Object.keys(data).forEach((key) => {
                    promotion += data[key].content + "　"
                })
            }
            return promotion
        }
    }
}
